import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const ChatDataContext = createContext({
    chatData: { threads: [] },
    isFetchingChat: false,
    fetchChatData: () => {} // Provide a no-op function as default
  });
export const ChatDataProvider = ({ children }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [chatData, setChatData] = useState({
    threads: []
  });
  const [isFetchingChat, setIsFetchingChat] = useState(false);

  const fetchChatData = async () => {
    setIsFetchingChat(true);
    try {
      const accessToken = await getAccessTokenSilently();

      // API call for chat data
      const threadsResponse = await axios.get("../../api/getThreads", {
        headers: { "Authorization": `Bearer ${accessToken}` }
      });


      setChatData({
        threads: threadsResponse.data,
      });
    } catch (error) {
      console.error("Failed to fetch chat threads:", error);
    } finally {
      setIsFetchingChat(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchChatData();
    }
  }, [getAccessTokenSilently, user?.sub]);

  return (
    <ChatDataContext.Provider value={{ chatData, isFetchingChat, fetchChatData }}>
      {children}
    </ChatDataContext.Provider>
  );
};

export const useChatData = () => useContext(ChatDataContext);