import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  UsersIcon,
  CurrencyDollarIcon,
  BriefcaseIcon,
  DocumentPlusIcon,
} from "@heroicons/react/24/outline";
import posthog from "posthog-js";
import Banner from "./Banner.js";

import { IconSpinner } from "./ui/Icons.js";
import MyAssistants from "./MyAssistants.js";
import SubscribeManageAccount from "./SubscribeManageAccount.js";
import Docs from "./Docs.js";

const ActionCard = ({
  Icon,
  title,
  description,
  onClick,
  iconColor,
  active,
}) => (
  <button
    onClick={onClick}
    className={`border py-2 md:py-4 px-5 transition duration-200 ease-in-out ${
      active ? "shadow-lg border-blue-500 border-2" : ""
    } hover:border-blue-400 hover:shadow-md`}
  >
    <div className="flex">
      <div className="items-start">
        <Icon className={`h-8 w-8 md:h-12 md:w-12 ${iconColor}`} />
      </div>
      <div className="mx-auto justify-center items-center text-center">
        <h2 className="font-bold text-md md:text-lg">{title}</h2>
        <p className="text-sm md:text-md text-gray-600">{description}</p>
      </div>
    </div>
  </button>
);

const DisabledCard = ({ Icon, title, description, iconColor, isFetching }) => (
  <div className="border py-2 md:py-4 px-5 bg-gray-200">
    <div className="flex">
      <div className="items-start">
        <Icon className={`h-8 w-8 md:h-12 md:w-12 ${iconColor}`} />
      </div>
      <div className="mx-auto justify-center items-center text-center">
        <h2 className="font-bold text-md md:text-lg">{title}</h2>
        <p className="text-sm md:text-md text-gray-600">
          {isFetching ? "Loading..." : description}
        </p>
      </div>
    </div>
  </div>
);

const Account = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();
  const [agents, setAgents] = useState([]);
  const [activeComponent, setActiveComponent] = useState("MyAssistants");
  const [subscriptionStatus, setSubscriptionStatus] = useState([]);
  const [isSubscriptionFetching, setIsSubscriptionFetching] = useState(true);

  useEffect(() => {
    const getUserSubscriptionStatus = async () => {
      if (!isAuthenticated) {
        setIsSubscriptionFetching(false); // Ensure loading is set to false if not authenticated
        return;
      }
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch("/api/getUser", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        // Set the subscription status in the state
        // remember, subscriptionStatus is an object with userInfo and subscription keys
        // it doesn't have the assistants in it
        setSubscriptionStatus(data);
        setIsSubscriptionFetching(false);

        // Identify the user with PostHog after successfully fetching their metadata
        posthog.identify(user.sub, {
          email: data.userInfo.email,
          name: data.userInfo.name,

          // Add any other user properties you wish to track here
        });
      } catch (error) {
        console.error("Error fetching subscription status:", error);
        setIsSubscriptionFetching(false);
        // Check if the error is related to an invalid refresh token
        if (
          error.error === "login_required" ||
          error.error === "invalid_grant"
        ) {
          // Log the user out
          logout({ returnTo: window.location.origin });
        }
        // You might want to handle other types of errors differently
      }
    };

    getUserSubscriptionStatus();
  }, [isAuthenticated, getAccessTokenSilently, logout]);

  if (isLoading || isSubscriptionFetching) {
    return <div className="flex justify-center items-center h-screen">
      <IconSpinner className="h-12 w-12 animate-spin" />
      <p className="text-gray-500">Loading...</p>
    </div>;
  }

  return (
    <div className="pb-12 flex flex-col">
      <Banner subscriptionStatus={subscriptionStatus} isSubscriptionFetching={isSubscriptionFetching} />
      <div className="w-5/6 md:max-w-5xl mx-auto mt-2">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <ActionCard
            Icon={UsersIcon}
            title="My Assistants"
            description="Manage your AI Assistants"
            onClick={() => setActiveComponent("MyAssistants")}
            iconColor="text-emerald-800"
            active={activeComponent === "MyAssistants"}
          />

          <ActionCard
            Icon={BriefcaseIcon}
            title="My Account"
            description="Manage your Subscription and Integrations"
            onClick={() => setActiveComponent("SubscribeManageAccount")}
            iconColor="text-amber-900"
            active={activeComponent === "SubscribeManageAccount"}
          />

          <ActionCard
            Icon={DocumentPlusIcon}
            title="Use Advanced Features"
            description="Leverage the Full Power of Appified"
            onClick={() => setActiveComponent("Docs")}
            iconColor="text-sky-500"
            active={activeComponent === "Docs"}
          />
        </div>
      </div>

      <hr className="my-4 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />

      {(() => {
        switch (activeComponent) {
          case "MyAssistants":
            return <MyAssistants subscriptionStatus={subscriptionStatus} isSubscriptionFetching={isSubscriptionFetching} />;

          case "SubscribeManageAccount":
            return <SubscribeManageAccount user={user} />;
          case "Docs":
            return <Docs />;

          default:
            return null;
        }
      })()}
    </div>
  );
};

export default Account;
