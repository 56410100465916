// Chat.js
"use client";

import React, { useState, useEffect, useCallback, useContext } from "react";
import { useAssistant, Message } from "ai/react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { useAuth0 } from "@auth0/auth0-react";
import remarkGfm from "remark-gfm";

import {
  ArrowUpCircleIcon,
  ArrowDownTrayIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";

import { ButtonScrollToBottom } from "./Button-scroll-to-bottom";
import AssistantSplash from "./AssistantSplash";
import { IconSpinner } from "./ui/Icons";
import { ChatInputForm } from "./PromptForm";
import { useScrollAnchor } from "./hooks/use-scroll-anchor";

import { useChatData } from "./contexts/ChatHistory";

import { getFileExtension } from "./lib/mime";

const domain = "appified.us.auth0.com";

const handleStatus = (status, lastError) => {
  let message = "";
  switch (status) {
    case "failed":
      message = `OpenAI Error: ${lastError.code} - ${lastError.message}`;
      break;
    case "cancelled":
      message = "OpenAI Error: The operation has been cancelled.";
      break;
    case "expired":
      message = "OpenAI Error: The operation has expired.";
      break;
    default:
      message = "An unexpected status has occurred.";
  }
  alert(message); // Or replace with a more sophisticated notification system
};

// Function to handle copying text to clipboard
const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert("Copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

const Chat = () => {
  const { user, getAccessTokenSilently } = useAuth0(); // Use Auth0 hook
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [isLoadingAssistant, setIsLoadingAssistant] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [attachments, setAttachments] = useState([]); // State for file attachments
  const [downloadedFiles, setDownloadedFiles] = useState([]); // State to track downloaded files
  const [iframeAuthToken, setIframeAuthToken] = useState(null); // State to hold the authToken

  const { chatData, isFetchingChat, fetchChatData } = useChatData();

  const { assistantId, threadId: initialThreadId } = useParams();
  const { messagesRef, scrollRef, visibilityRef, isAtBottom, scrollToBottom } =
    useScrollAnchor();

  const location = useLocation();
  const navigate = useNavigate();
  const currentPathSegment = location.pathname.includes("/embed/")
    ? "/embed/"
    : "/chat/";

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("authToken");
    setIframeAuthToken(token); // Set the authToken in state

    if (token) {
      console.log(`AuthToken: ${token}`);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (user) {
        try {
          const token = await getAccessTokenSilently();
          setAccessToken(token);
        } catch (error) {
          console.error("Error fetching access token:", error);
          setAccessToken(null);
        }
      } else {
        setAccessToken(null);
      }
    };

    fetchAccessToken();
  }, [user, getAccessTokenSilently]);

  const assistantHeaders = accessToken
    ? { Authorization: `Bearer ${accessToken}` }
    : {};

  const {
    status,
    messages,
    setMessages,
    threadId,
    input,
    submitMessage: originalSubmitMessage,
    handleInputChange,
    error,
    append,
  } = useAssistant({
    api: "/api/newChat",
    threadId: initialThreadId,
    headers: assistantHeaders,
    body: {
      assistantId: assistantId,
      ...(iframeAuthToken ? { authToken: iframeAuthToken } : {}),
    },
    onError: (error) => {
      console.error("Assistant Error:", error);
      alert(`Error: ${error}`);
    },
  });

  // Fetch chat history when threadId is initialized
  useEffect(() => {
    if (threadId) {
      fetchChatData(threadId);
    }
  }, [threadId]);

  const submitMessage = async (inputText, attachments) => {
    if (inputText.trim()) {
      // Prepare the additional data you want to send with the message
      const additionalData = {
        attachments: attachments,
      };

      // Call the original submitMessage function with requestOptions
      await originalSubmitMessage(undefined, {
        data: additionalData,
      });

      setAttachments([]);
    }
  };

  useEffect(() => {
    const fetchAssistantDetails = async () => {
      setIsLoading(true);
      setIsLoadingAssistant(true);

      let token;
      try {
        if (user) {
          token = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
          });
        }

        if (token) {
          const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
          await fetch(userDetailsByIdUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }

        const assistantResponse = await axios.get("/api/getChat", {
          params: {
            assistantId: assistantId,
          },
        });

        if (assistantResponse.data.assistant) {
          setSelectedAssistant(assistantResponse.data.assistant);
          setIsLoadingAssistant(false);
        }
      } catch (error) {
        console.error("Error fetching assistant:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAssistantDetails();
  }, [assistantId]);

  useEffect(() => {
    const fetchThreadMessages = () => {
      setIsLoading(true);
      const interval = setInterval(async () => {
        try {
          const chatResponse = await axios.get("/api/getChat", {
            params: {
              threadId: initialThreadId || threadId, // Use initialThreadId if present, this means it came from the URL. otherwise use threadId because it's from the assistant hook
              assistantId: assistantId,
            },
          });

          if (!selectedAssistant && chatResponse.data.assistant) {
            setSelectedAssistant(chatResponse.data.assistant);
          }

          const apiStatus = chatResponse.data.run_status.status;
          if (chatResponse.data.messages) {
            setResponseMessage(chatResponse.data.messages.reverse());

            // Check if messages are empty and initialThreadId is populated... this means we got a thread in the URL
            // another scenario to reset messages is if the threadId is different from the threadId of the first message in chatResponse... this means we navigated to a different thread
            if (
              (messages.length === 0 && initialThreadId) ||
              (chatResponse.data.messages?.length > 0 &&
                initialThreadId &&
                chatResponse.data.messages[0].thread_id !== threadId)
            ) {
              // Transform messages to the expected format
              const transformedMessages = chatResponse.data.messages.map(
                (message) => ({
                  id: message.id,
                  role: message.role,
                  content: message.content
                    .map((contentItem) => {
                      // Check for text type content and return its value
                      if (contentItem.type === "text") {
                        return contentItem.text.value;
                      }
                      // Handle other types like image_file, etc.
                      if (contentItem.type === "image_file") {
                        return `Image: ${contentItem.image_file.file_id}`;
                      }
                      return ""; // Return empty string for unrecognized types
                    })
                    .join("  \n"), // Join all content parts with newline
                })
              );

              setMessages(transformedMessages);
            }

            chatResponse.data.messages.forEach((message) => {
              // Only process messages from the assistant for downloading
              if (message.role === "assistant") {
                message.content.forEach((content) => {
                  if (
                    content.type === "image_file" &&
                    !downloadedFiles.some(
                      (f) => f.fileId === content.image_file.file_id
                    )
                  ) {
                    fetchFileForDisplay(
                      assistantId,
                      content.image_file.file_id
                    );
                  }
                });
              }
            });
          }

          if (apiStatus === "completed") {
            // Handle completed status as before
            clearInterval(interval);
            setIsLoading(false);
          } else if (["expired", "cancelled", "failed"].includes(apiStatus)) {
            // Handle other statuses with alerting
            handleStatus(apiStatus, chatResponse.data.run_status.last_error);
            clearInterval(interval);
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Get Chat Failed:", error);
          setIsLoading(false);
          clearInterval(interval);
        }
      }, 3500);
      return () => {
        clearInterval(interval);
        setIsLoading(false);
      };
    };

    if (threadId || initialThreadId) {
      // If threadId is present, fetch thread messages
      return fetchThreadMessages();
    }
  }, [threadId, initialThreadId, status]);

  const fetchFileForDisplay = async (assistantId, fileId) => {
    try {
      const response = await axios.post(
        `/api/fetchFile`,
        { assistantId, fileId },
        {
          headers: { "Content-Type": "application/json" },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadedFiles((prev) => [...prev, { fileId, url }]);
    } catch (error) {
      console.error("Error fetching image:", error);
      alert("Failed to fetch image.");
    }
  };

  const downloadFile = async (assistantId, fileId) => {
    try {
      const response = await axios.post(
        `/api/fetchFile`,
        { assistantId, fileId },
        {
          headers: { "Content-Type": "application/json" },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: response.data.type }); // Ensure the blob type is set
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

    // Get filename from content-disposition header
    const contentDisposition = response.headers['content-disposition'];
    let filename = fileId; // Default to fileId if we can't extract the filename

    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
      if (filenameMatch) {
        filename = filenameMatch[1];
        // Clean up the filename
        filename = filename.replace(/^\/mnt\/data\//, ''); // Remove leading /mnt/data/ if present
        filename = filename.replace(/"/g, ''); // Remove any remaining quotes
        filename = filename.trim(); // Trim any leading/trailing spaces
      }
    }

    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading file:", error);
    alert("Failed to download file.");
  }
};

  return (
    <div className="flex justify-center items-center h-full">
      <div className="p-2 flex flex-col h-full max-w-5xl justify-center items-center">
        {messages && messages.length === 0 && (
          <AssistantSplash
            isLoadingAssistant={isLoadingAssistant}
            selectedAssistant={selectedAssistant}
            handleInputChange={handleInputChange}
            originalSubmitMessage={originalSubmitMessage}
            append={append}
            // Pass any additional props you might need for the new stuff you plan to add
          />
        )}
        <div className="fixed inset-x-0 bottom-0 w-full duration-300 ease-in-out animate-in dark:from-background/10 dark:from-10% dark:to-background/80 peer-[[data-state=open]]:group-[]:lg:pl-[250px] peer-[[data-state=open]]:group-[]:xl:pl-[300px] overflow-hidden">
          {messages && messages.length > 0 && (
            <div>
              <ButtonScrollToBottom
                isAtBottom={isAtBottom}
                scrollToBottom={scrollToBottom}
              />
              <div
                className="mx-auto sm:max-w-2xl md:max-w-4xl px-4 h-full"
                ref={scrollRef}
              >
                <div
                  className="flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    navigate(`${currentPathSegment}${assistantId}`);
                  }}
                >
                  {selectedAssistant && selectedAssistant.logo_url && (
                    <img
                      src={selectedAssistant.logo_url}
                      className="w-10 h-10 object-cover rounded-full mr-2"
                      alt={`Logo of ${selectedAssistant.name}`}
                    />
                  )}
                  <h2 className="text-xl text-center font-bold flex items-center justify-center">
                    {selectedAssistant ? selectedAssistant.name : "Loading..."}
                    {(isLoading || isLoadingAssistant) && <IconSpinner />}
                  </h2>
                </div>

                <div
                  className="flex flex-col items-center overflow-auto w-full max-h-[calc(100vh-200px)] pb-10"
                  ref={messagesRef}
                >
                  {messages.map((m, index) => (
                    <div
                      key={index}
                      className={`flex w-full my-2 ${
                        m.role === "assistant" || m.role === "data"
                          ? "justify-start"
                          : "justify-end"
                      }`}
                    >
                      {m.role === "assistant" && (
                        <img
                          src={selectedAssistant.logo_url}
                          alt={selectedAssistant.name}
                          className="w-8 h-8 object-cover rounded-full self-start mr-2"
                          onError={(e) => (e.target.style.display = "none")}
                        />
                      )}
                      <div
                        className={`${
                          m.role === "assistant" || m.role === "data"
                            ? "prose prose-stone"
                            : "bg-gray-100 dark:bg-zinc-950 rounded-xl p-2"
                        } `}
                      >
                        {m.role === "data" && !m.data?.error && (
                          <details className="p-2 text-sm rounded">
                            {m.data?.description && (
                              <>
                                <summary>Making a tool call</summary>
                                <pre className="whitespace-pre-wrap text-xs">
                                  {JSON.stringify(m.data, null, 2)}
                                </pre>
                              </>
                            )}
                          </details>
                        )}
                        {m.role === "data" && m.data?.error && (
                          <div
                            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                            role="alert"
                          >
                            <strong className="font-bold">Error: </strong>
                            <span className="block sm:inline">
                              {m.data.message}
                            </span>
                            {m.data.description && (
                              <p className="text-sm mt-2">
                                {m.data.description}
                              </p>
                            )}
                          </div>
                        )}
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {m.role !== "data" && m.content}
                        </ReactMarkdown>
                        {m.role === "assistant" && (
                          <div className="group relative inline-block">
                            <ClipboardDocumentIcon
                              className="w-5 h-5 cursor-pointer"
                              onClick={() => copyToClipboard(m.content)}
                            />
                            <span className="invisible group-hover:visible hidden sm:inline-block opacity-0 group-hover:opacity-100 transition bg-gray-900 text-white text-xs p-1 rounded absolute top-full mt-2 whitespace-nowrap">
                              Copy
                            </span>
                          </div>
                        )}
                        {responseMessage
                          .filter(
                            (rm) => rm.id === m.id && m.role === "assistant"
                          )
                          .flatMap((rm) => [...rm.content, ...rm.attachments])
                          .map((item, idx) => {
                            if (item.type === "image_file") {
                              const file = downloadedFiles.find(
                                (f) => f.fileId === item.image_file.file_id
                              );
                              return (
                                <div key={idx} className="p-2">
                                  {file ? (
                                    <img src={file.url} alt="Attachment" />
                                  ) : (
                                    <p>Loading image...</p>
                                  )}
                                </div>
                              );
                            } else if (item.file_id) {
                              return (
                                <div key={`attachment-${idx}`} className="p-2">
                                  <button
                                    onClick={() =>
                                      downloadFile(assistantId, item.file_id)
                                    }
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                                  >
                                    <ArrowDownTrayIcon className="w-5 h-5 mr-2" />
                                    Download File
                                  </button>
                                </div>
                              );
                            }
                            return null;
                          })}
                      </div>
                      {m.role === "user" && user && user.picture && (
                        <img
                          src={user.picture}
                          alt={user.name}
                          className="w-8 h-8 object-cover rounded-full self-start ml-2"
                          onError={(e) => (e.target.style.display = "none")}
                        />
                      )}
                    </div>
                  ))}
                  <div ref={visibilityRef}></div>{" "}
                  {/* Attach visibilityRef here */}
                </div>
              </div>
            </div>
          )}
          <div className="space-y-4 mx-auto sm:max-w-2xl md:max-w-4xl sm:px-4 border-t bg-background px-4 py-2 shadow-lg sm:rounded-t-xl sm:border md:py-4">
            <ChatInputForm
              selectedAssistant={selectedAssistant}
              status={status}
              input={input}
              submitMessage={submitMessage}
              handleInputChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
