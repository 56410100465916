import React from "react";
import { IconSpinner } from "./ui/Icons";

const AssistantSplash = ({ isLoadingAssistant, selectedAssistant, handleInputChange, originalSubmitMessage, append }) => {
  const defaultLogoUrl = "/favicon.png";


  const handleSuggestionClick = async (suggestion) => {
    await append(
      { role: "user", content: suggestion },
      { options: {} }
    );
  };

  return (
    <div className="flex flex-col justify-center items-center mb-4">
      {isLoadingAssistant ? (
        <IconSpinner className="mt-20 w-32 h-32 animate-spin" />
      ) : (
        <img
          src={selectedAssistant?.logo_url || defaultLogoUrl}
          className="mt-20 w-32 h-32 object-cover rounded-full"
          alt={`Logo of ${selectedAssistant?.name || "Assistant"}`}
        />
      )}
      <h1 className="text-3xl font-bold text-center">
        {isLoadingAssistant
          ? "Loading your Assistant..."
          : `Chat with ${selectedAssistant?.name}`}
      </h1>
      {selectedAssistant && selectedAssistant.branding_required && (
        <p className="text-center italic text-md">
          Powered by{" "}
          <a
            href="https://appified.ai"
            className="underline hover:text-blue-800"
          >
            Appified.ai
          </a>
        </p>
      )}
      {selectedAssistant && selectedAssistant.subscription_required && (
        <div className="bg-yellow-100 text-black px-3 py-1 rounded-full text-sm mt-2">
          Subscription Required to use this Assistant
        </div>
      )}
            {selectedAssistant && selectedAssistant.suggestions && (
        <div className="mt-4 flex flex-wrap justify-center">
        {selectedAssistant.suggestions.map((suggestion, index) => (
            <button
              key={index}
              className="bg-gray-100 dark:bg-zinc-950 text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-zinc-900 text-sm py-2 px-4 rounded-xl m-1"
              onClick={() => {handleSuggestionClick(suggestion);}}
            >
              {suggestion}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default AssistantSplash;
