import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Routes, useNavigate, useParams, Outlet } from 'react-router-dom';
import { Auth0Provider, withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'; // Import the Auth0 hook
import HomePage from './HomePage';
import Header from "./Header";
import Chat from "./Chat";
import Account from "./Account";
import RequestForm from "./ContactUs";
import logo from './logo.svg';
import './App.css';
import posthog from 'posthog-js';
import ReactGA from "react-ga4";

import { ChatDataProvider } from './contexts/ChatHistory';

const ProtectedRoute = ({ component: Component, ...props }) => {
  const ComponentWithAuth = withAuthenticationRequired(Component, props);
  return <ComponentWithAuth {...props} />;
};

const EmbedRoute = () => {
  const [isEmbedProtected, setIsEmbedProtected] = useState(true); // Default to protected
  const [isLoading, setIsLoading] = useState(true);
  const { assistantId } = useParams();

  useEffect(() => {
    const fetchAssistant = async () => {
      const assistantResponse = await axios.get("/api/getChat", {
        params: {
          assistantId: assistantId,
          embed: true
        }
      });

      const assistant = assistantResponse.data;
      setIsEmbedProtected(assistant.authentication_required || false);
      setIsLoading(false);
    };

    fetchAssistant();
  }, [assistantId]);

  if (isLoading) {
    return <div>Loading...</div>; // Or your custom loading component
  }

  return isEmbedProtected ? <ProtectedRoute component={Chat} /> : <Chat />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const ChatLayout = () => {
  return (
    <ChatDataProvider>
        <Outlet />
    </ChatDataProvider>
  );
};

export default function App() {

  useEffect(() => {
    ReactGA.initialize("G-BKZMLW9WWW");
    posthog.init('phc_zvpiuioCaTxvi5CVs8GGT4T2Vmkj23gHaTbJg8vWqhy', { api_host: 'https://app.posthog.com' })
  }, []);

  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain="appified.us.auth0.com"
        clientId="XTi8cnTdc5b3utCZD4tFstIX5vGsXwqj"
        authorizationParams={{
          redirect_uri: `${window.location.origin}/account`,
          audience: "https://appified.us.auth0.com/api/v2/",
          scope: "openid profile email read:current_user update:current_user_metadata offline_access",
        }}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <Routes>
          <Route path="/" element={<HeaderWrapper><HomePage /></HeaderWrapper>} />
          <Route element={<ChatLayout />}>
          <Route path="/account" element={<HeaderWrapper><ProtectedRoute component={Account} /></HeaderWrapper>} />
          <Route path="/chat/:assistantId/:threadId?" element={<HeaderWrapper><ProtectedRoute component={Chat} /></HeaderWrapper>} />
          <Route path="/embed/:assistantId/:threadId?" element={<EmbedRoute />} />
          </Route>
          <Route path="/contactus" element={<HeaderWrapper><RequestForm /></HeaderWrapper>} />
        </Routes>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}

const HeaderWrapper = ({children}) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return (
    <>
      <Header isAuthenticated={isAuthenticated} loginWithRedirect={loginWithRedirect} />
      {children}
    </>
  );
};