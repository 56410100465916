import React from "react";
import { HashLink } from "react-router-hash-link";
import { Disclosure } from "@headlessui/react";
import {
  ChatBubbleLeftEllipsisIcon,
  UserCircleIcon,
  ServerIcon,
  CurrencyDollarIcon,
  ChevronUpIcon,
  CheckBadgeIcon,
  ChartPieIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Your OpenAI Assistant. Your site.",
    description:
      "Appified connects to your OpenAI account so you own the data and the AI Assistant.",
    icon: UserCircleIcon,
  },
  {
    name: "Supports function calling for your Assistant.",
    description:
      "Appified responds to function calls for your Assistant, so it can call Webhooks, communicate with APIs, scrape websites, and more.",
    icon: ChatBubbleLeftEllipsisIcon,
  },
  {
    name: "Require a subscription for users to chat with your AI Assistant.",
    description:
      "Appified integrates with your Stripe account to handle payments and user management.",
    icon: CurrencyDollarIcon,
  },
  {
    name: "Admin Analytics.",
    description:
      "See how your users are interacting with each of your AI Assistants.",
    icon: ChartPieIcon,
  },
];

const faqs = [
  {
    question: "Can I use a Custom GPT with Appified?",
    answer: (
      <span>
        No, but migrating your Custom GPT to an OpenAI Assistant only takes a
        few minutes. Copy your instructions and download the files from your My
        GPTs page (
        <a
          href="https://chat.openai.com/gpts/mine"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          https://chat.openai.com/gpts/mine
        </a>
        ), then on the Assistants page (
        <a
          href="https://platform.openai.com/assistants"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          https://platform.openai.com/assistants
        </a>
        ) click '+ Create' and copy + upload the information to create your
        first OpenAI Assistant!
      </span>
    ),
  },
  {
    question: "How do I get started?",
    answer: (
      <span>
        1. Create an OpenAI account (
        <a
          href="https://platform.openai.com/login?launch"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          https://platform.openai.com/login?launch
        </a>
        ) <br />
        2. Create an OpenAI Assistant (
        <a
          href="https://platform.openai.com/assistants"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          https://platform.openai.com/assistants
        </a>
        ) <br />
        3. Create an Appified account (
        <a
          href="https://appified.ai/account"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          https://appified.ai/account
        </a>
        ) <br />
        4. Connect your OpenAI account to Appified by saving your OpenAI API key
        in Appified
        <br />
        5. Select the Assistant you want to Appify
        <br />
        6. Embed or share the link to your AI Assistant
        <br />
        7. Add a Stripe account to charge users
      </span>
    ),
  },
  {
    question: "Do I need to know how to code?",
    answer: (
      <span>
        No, Appified is a no-code tool, and we integrate with OpenAI which has a
        no-code playground to help you build your AI Assistant.
      </span>
    ),
  },
  {
    question: "What can I do with my AI Assistant?",
    answer: (
      <span>
        You can embed your AI Assistant on your website, share the link to your
        AI Assistant, or use it in your own app. You can also charge users to
        use your AI Assistant.
      </span>
    ),
  },
  {
    question: "How does Appified keep my OpenAI API key private and secure?",
    answer: (
      <span>
        Appified uses our API to connect to your OpenAI Assistant, so users of
        your Assistant never see your OpenAI API key. We store the API key in a
        secure, encrypted database and only use it to make API calls to OpenAI.
      </span>
    ),
  },
  {
    question: "How can I charge users to use my AI Assistant?",
    answer: (
      <span>
        You provide a Stripe API key and a list of Stripe product IDs to
        Appified when configuring your Assistant. Appified handles the rest,
        calling the Stripe API to verify if the user has a valid subscription
        before allowing them to chat with your Assistant.
      </span>
    ),
  },
];

function PricingCard({
  title,
  description,
  price,
  features,
  enterprise = false,
}) {
  return (
    <div className="flex flex-col flex-1 mx-3 my-6 bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-2">
      <div className="p-8 flex flex-col h-full">
        <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
          {title}
        </div>
        <div className="mt-3">
          <span className="text-4xl font-bold">{price}</span>
          {!enterprise && <span className="text-gray-500">/month</span>}
        </div>
        <p className="mt-2 text-gray-500">{description}</p>
        <ul className="mt-4 mb-4 text-gray-600 text-left flex-grow">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center p-2">
              <CheckBadgeIcon className="h-7 w-7 text-indigo-500 mr-2 flex-shrink-0" />
              {feature}
            </li>
          ))}
        </ul>
        <div className="flex justify-center mt-auto">
          <a
            href={enterprise ? "mailto:patrick@appified.ai" : "/account"}
            className="mt-5 px-8 py-4 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
          >
            {enterprise ? "Contact Us" : "Get started"}
          </a>
        </div>
      </div>
    </div>
  );
}

const HomePage = () => {
  return (
    <div className="bg-white">
      <section className="pb-8 bg-white sm:pb-12 lg:pb-12">
        <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-36">
          <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div className="lg:mt-16">
              <div className="mx-auto text-center sm:max-w-lg lg:max-w-xl lg:text-left lg:mx-0">
                <h1 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl xl:text-7xl sm:tracking-tight">
                  Embed your OpenAI Assistant on your website
                </h1>
                <p className="mt-6 text-lg leading-7 text-gray-700 lg:leading-8 lg:text-xl">
                  No code needed. Powered by the OpenAI Assistants API with your
                  OpenAI API key so you are in control.
                </p>
              </div>

              <div className="flex flex-col items-stretch justify-center gap-4 mt-8 sm:flex-row sm:items-center sm:mt-10 lg:justify-start sm:flex-wrap">
                <a
                  href="/account"
                  title=""
                  className="inline-flex items-center justify-center px-8 py-4 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                  role="button"
                >
                  Start using Appified
                </a>

                <a
                  href="https://www.youtube.com/watch?v=kqKf9Vwh2zM"
                  target="_blank"
                  rel="noopener noreferrer"
                  title=""
                  className="inline-flex items-center justify-center px-8 py-4 text-base font-medium text-gray-900 transition-all duration-200 border border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                  role="button"
                >
                  <svg
                    className="w-6 h-6 mr-3 -ml-1 text-blue-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Watch 4 min intro
                </a>
              </div>
            </div>
          </div>

          <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
            <div className="py-12 mt-6 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <div className="relative pl-10 -mr-40 sm:max-w-3xl lg:max-w-none lg:h-full lg:pl-24">
                <video
                  src="/appified_demo2.mp4"
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="w-full shadow-2xl rounded-xl lg:rounded-2xl lg:h-full lg:w-auto lg:max-w-none"
                  width={2432}
                  height={1442}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#b3cde0] to-[#4169e1] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>

      <section className="py-12 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-xl mx-auto text-center xl:max-w-2xl">
            <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl xl:text-5xl font-pj">
              Embed your OpenAI Assistant on your website in less than 5 minutes
            </h2>
          </div>

          <div className="grid max-w-4xl grid-cols-1 mx-auto mt-8 text-center gap-y-4 sm:gap-x-8 sm:grid-cols-2 sm:mt-12 lg:mt-20 sm:text-left">
            <div className="space-y-4 sm:space-y-8">
              <div className="overflow-hidden bg-white shadow-md rounded-xl">
                <div className="p-9">
                  <img
                    src="/openai-logos/SVGs/openai-logomark.svg"
                    alt="OpenAI Logomark"
                    className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                  />
                  <h3 className="mt-8 text-2xl font-bold text-gray-900 sm:mt-20 font-pj">
                    Your OpenAI Assistant. Your site.
                  </h3>
                  <p className="mt-6 text-base text-gray-600 font-pj">
                    Appified connects to your OpenAI account via API key so you
                    own the data and the AI Assistant.
                  </p>
                  <p className="mt-6 text-base text-gray-600 font-pj">
                    {" "}
                    Manage your system prompts, files, and other settings in the{" "}
                    <a
                      href="https://platform.openai.com/assistants"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800"
                    >
                      OpenAI Playground
                    </a>
                    .
                  </p>
                  <p className="mt-6 text-base text-gray-600 font-pj">
                    {" "}
                    Configure branding and embed settings in Appified, and get your iframe embed code.
                  </p>
                </div>
              </div>

              <div className="overflow-hidden bg-white shadow-md rounded-xl">
                <div className="p-9">
                  <CurrencyDollarIcon className="w-12 h-12 mx-auto text-gray-400 sm:mx-0" />
                  <h3 className="mt-8 text-2xl font-bold text-gray-900 sm:mt-20 font-pj">
                    Require Subscriptions via Stripe
                  </h3>
                  <p className="mt-6 text-base text-gray-600 font-pj">
                    If you want to charge for access to your Assistant, Appified
                    integrates with your Stripe account to handle subscription
                    checks for you.
                  </p>
                </div>
              </div>
            </div>

            <div className="space-y-4 sm:space-y-8">
              <div className="relative">
                <div className="absolute -inset-1">
                  <div
                    className="w-full h-full rotate-180 opacity-30 blur-lg filter"
                    style={{
                      background:
                        "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
                    }}
                  ></div>
                </div>

                <div className="relative overflow-hidden bg-white shadow-md rounded-xl">
                  <div className="p-9">
                    <WrenchScrewdriverIcon className="w-12 h-12 mx-auto text-gray-400 sm:mx-0" />
                    <h3 className="mt-8 text-2xl font-bold text-gray-900 sm:mt-20 font-pj">
                      Support for Making API Calls
                    </h3>
                    <p className="mt-6 text-base text-gray-600 font-pj">
                      Appified responds to function calls for your Assistant, so
                      it can call webhooks, communicate with APIs, scrape
                      websites, and more.
                    </p>
                    <p className="mt-6 text-base text-gray-600 font-pj">
                      Appified users connect their Assistants to{" "}
                      <a
                        href="https://zapier.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800"
                      >
                        Zapier
                      </a>
                      ,{" "}
                      <a
                        href="https://www.make.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800"
                      >
                        Make.com
                      </a>
                      , and other tools to make their Assistants more powerful
                      without writing code.
                    </p>
                  </div>
                </div>
              </div>

              <div className="overflow-hidden bg-white shadow-md rounded-xl">
                <div className="p-9">
                  <ChartPieIcon className="w-12 h-12 mx-auto text-gray-400 sm:mx-0" />
                  <h3 className="mt-8 text-2xl font-bold text-gray-900 sm:mt-20 font-pj">
                    Admin Analytics
                  </h3>
                  <p className="mt-6 text-base text-gray-600 font-pj">
                    See how your users are interacting with each of your AI
                    Assistants with Appified's analytics, and links to view
                    their threads on OpenAI.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto text-center" id="pricing">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl font-pj">
              Pricing
            </h2>
            <div className="flex flex-col md:flex-row sm:justify-center sm:space-x-6">
              <PricingCard
                title="Starter"
                description="Testing and development"
                price="$0"
                features={[
                  "Connect your OpenAI Account",
                  "Test your first assistant",
                ]}
              />
              <PricingCard
                title="Pro"
                description="Deploy your assistant to your website"
                price="$49"
                features={[
                  "Up to 3 assistants",
                  "No Appified branding",
                  "Subscription checks via Stripe",
                  "Chat history",
                ]}
              />
              <PricingCard
                title="Scaled"
                description="Scale out your deployed assistants"
                price="$299"
                features={[
                  "Unlimited Assistants",
                  "No Appified branding",
                  "User and assistant analytics",
                  "Subscription checks via Stripe",
                  "Chat history",
                  "Custom dev support for your first assistant",
                ]}
              />
              <PricingCard
                title="Enterprise"
                description="Build a custom solution with Appified"
                price="Contact us"
                enterprise={true}
                features={[
                  "Unlimited Assistants",
                  "Support for multiple admins",
                  "Custom integrations to your apps",
                  "Custom branding",
                  "User and assistant analytics",
                  "Chat history and user management",
                  "Custom dev support by Appified engineers",
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              Don't just take our word for it. Over 250 companies are building
              with Appified.
            </h2>
          </div>

          <div className="grid max-w-md grid-cols-1 gap-5 mx-auto mt-12 sm:mt-16 lg:grid-cols-3 lg:max-w-none">
            <div className="bg-gray-100 rounded-2xl xl:-rotate-2">
              <div className="p-8">
                <blockquote>
                  <p className="text-xl font-medium leading-9 text-gray-900">
                    "Appified is a game-changer for us. I don't know how to code
                    but I was able to get my AI Assistant up and running in
                    minutes."
                  </p>
                </blockquote>
                <p className="mt-6 text-base font-semibold text-gray-900">
                  Charlie
                </p>
                <p className="mt-1 text-base font-normal text-gray-600">
                  Co-Founder, CEO
                </p>
                <div className="flex items-center justify-between pt-5 mt-5 border-t border-gray-200"></div>
              </div>
            </div>

            <div className="bg-gray-100 rounded-2xl xl:rotate-2">
              <div className="p-8">
                <blockquote>
                  <p className="text-xl font-medium leading-9 text-gray-900">
                    "I have continued to search for solutions similar to yours
                    and you have the best one so far."
                  </p>
                </blockquote>
                <p className="mt-6 text-base font-semibold text-gray-900">
                  Dan
                </p>
                <p className="mt-1 text-base font-normal text-gray-600">
                  Founder
                </p>
                <div className="flex items-center justify-between pt-5 mt-5 border-t border-gray-200"></div>
              </div>
            </div>

            <div className="bg-gray-100 rounded-2xl xl:-rotate-2">
              <div className="p-8">
                <blockquote>
                  <p className="text-xl font-medium leading-9 text-gray-900">
                    "Appified was extremely responsive and helpful in getting me
                    set up with my first AI Assistant, and adding the ability to
                    charge users was a huge unlock for me."
                  </p>
                </blockquote>
                <p className="mt-6 text-base font-semibold text-gray-900">
                  Sarah
                </p>
                <p className="mt-1 text-base font-normal text-gray-600">
                  AI Consultant
                </p>
                <div className="flex items-center justify-between pt-5 mt-5 border-t border-gray-200"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="w-full px-4 pt-16">
        <h2
          className="text-3xl mb-6 text-center font-bold tracking-tight text-gray-900 sm:text-4xl font-pj"
          id="faq"
        >
          Frequently Asked Questions
        </h2>
        <div className="mx-auto w-full max-w-4xl rounded-2xl bg-white p-2">
          {faqs.map((faq, index) => (
            <Disclosure
              as="div"
              className={`${index > 0 ? "mt-2" : ""}`}
              key={faq.question}
            >
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-base font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                    <span>{faq.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pb-2 pt-4 text-base text-gray-500">
                    {faq.answer}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </div>
      </div>

      <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-4xl" id="aboutus">
          <img
            className="mx-auto h-12"
            src="https://www.asterave.com/aster_logo.png"
            alt="Aster Ave Logo"
          />
          <figure className="mt-10">
            <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
              <p>
                Appified is built by{" "}
                <a
                  href="http://www.asterave.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  Aster
                </a>
                , a small team that is passionate about generative AI and the
                future of work.
              </p>
            </blockquote>
          </figure>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
