const stripeProducts = {
    'prod_PO8LSm1VkaHqXb': {
        name: 'Appified.ai Pro',
        app_limit: 3
    },
    'prod_PO8MK6twmz1874': {
        name: 'Appified.ai Scale',
        app_limit: 100
    },
  };

export default stripeProducts;