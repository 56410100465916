import { useState, useEffect, Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Switch,
  Dialog,
  Transition,
  Listbox,
  Disclosure,
} from "@headlessui/react";
import { RiErrorWarningFill } from '@remixicon/react';
import { Badge, Card, Callout, Metric, Text, Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow, } from "@tremor/react";
import {
  ChatBubbleBottomCenterTextIcon,
  CodeBracketIcon,
  SparklesIcon,
  CheckIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  ChartPieIcon,
} from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import stripeProducts from "./StripeProducts";
import { IconSpinner } from "./ui/Icons.js";

import { useAuth0Helper } from "./auth0Helper";

const domain = "appified.us.auth0.com";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MyAssistants = ({ subscriptionStatus, isSubscriptionFetching }) => {
  const { user, getAccessTokenSilently } = useAuth0(); // Use Auth0 hook
  const [assistants, setAssistants] = useState([]); // Initialize assistants state
  const [assistantStatuses, setAssistantStatuses] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);
  const [isLoadingAnalytics, setIsLoadingAnalytics] = useState(false);
  const [analyticsData, setAnalyticsData] = useState({ threads: [], metrics: { threads: 0, authenticatedUserThreads: 0, anonymousUserThreads: 0 } });
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const { updateUserMetadata } = useAuth0Helper();
  const [isKeyMissing, setIsKeyMissing] = useState(false);

  const faqs = [
    {
      question: "Why do I need an OpenAI Account?",
      answer:
        "Appified uses OpenAI's API to power your assistants. In your OpenAI account you can create assistants, test assistant instructions, upload files, and more. You are in control of your assistants and their data.",
    },
    {
      question: "So why do I need Appified then?",
      answer:
        "OpenAI Assistants only communicate via API calls. Appified powers a chat interface and handles the API calls to OpenAI for you so you don't have to write any code. You can customize the look and feel of your assistants, and embed them on your website.",
    },
    {
      question: "How does Appified keep my OpenAI API key private and secure?",
      answer:
        "Appified uses our API to connect to your OpenAI Assistant, so users of your Assistant never see your OpenAI API key. We store the API key in a secure, encrypted database and only use it to make API calls to OpenAI.",
    },
    {
      question: "I still have a question...",
      answer:
        "No problem, click the Chat icon on the left to send us a message. We'll get back to you as soon as we can.",
    },
  ];

  // Find the user's current subscription
  const currentSubscription = subscriptionStatus.subscriptions?.find(
    (sub) => sub.status === "active"
  );
  const currentProduct = stripeProducts[currentSubscription?.plan?.product];

  // Count the number of appified assistants
  const appifiedCount = Object.values(assistantStatuses).filter(
    (status) => status.appified_status
  ).length;

  const authOptions = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];
  const subOptions = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];
  const brandingOptions = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];

  // set the default options for the modal
  const [selectedAuth, setSelectedAuth] = useState(authOptions[0]);
  const [selectedSub, setSelectedSub] = useState(subOptions[1]);
  const [selectedBranding, setSelectedBranding] = useState(brandingOptions[0]);
  const [logoUrl, setLogoUrl] = useState("");
  const [stripeSubRequired, setStripeSubRequired] = useState(false);
  const [stripeApiKey, setStripeApiKey] = useState("");
  const [stripeProductIds, setStripeProductIds] = useState([]);
  const [suggestions, setSuggestions] = useState([]); // Initialize suggestions state

  const removeSuggestion = (index) => {
    setSuggestions(suggestions.filter((_, i) => i !== index));
  };

  const openModal = (assistant) => {
    setSelectedAssistant(assistant);
    setIsOpen(true);
    setSelectedAuth(
      authOptions.find(
        (option) =>
          option.value ===
          assistantStatuses[assistant.id]?.authentication_required
      ) || authOptions[0]
    );
    setSuggestions(assistantStatuses[assistant.id]?.suggestions || []);
    setSelectedSub(
      subOptions.find(
        (option) =>
          option.value ===
          assistantStatuses[assistant.id]?.subscription_required
      ) || subOptions[0]
    );
    setSelectedBranding(
      brandingOptions.find(
        (option) =>
          option.value === assistantStatuses[assistant.id]?.branding_required
      ) || brandingOptions[0]
    );
    setLogoUrl(assistantStatuses[assistant.id]?.logo_url || "");
  };

  const closeModal = () => {
    setSelectedAssistant(null);
    setIsOpen(false);
  };

  const openAnalyticsModal = async (assistant) => {
    setSelectedAssistant(assistant);
    setIsAnalyticsOpen(true);
    setIsLoadingAnalytics(true);

    try {
      const token = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });

      const response = await fetch(`/api/getThreadsAdmin?assistant_id=${assistant.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch analytics data');
      }

      const { threads, metrics } = await response.json();
      setAnalyticsData({ threads, metrics });
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    } finally {
      setIsLoadingAnalytics(false);
    }
  };

  const closeAnalyticsModal = () => {
    setSelectedAssistant(null);
    setIsAnalyticsOpen(false);
    setAnalyticsData({ threads: [], metrics: { threads: 0, authenticatedUserThreads: 0, anonymousUserThreads: 0 } });
  };

  useEffect(() => {
    const fetchAssistants = async () => {
      const token = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { user_metadata } = await metadataResponse.json();

      // Extract the assistants from the user metadata
      const assistantsFromMetadata = user_metadata?.assistants || {};

      let openai_key;

      if (user_metadata && user_metadata.openai_key) {
        openai_key = user_metadata.openai_key;
      } else {
        console.error("openai_key is undefined");
        setIsKeyMissing(true); // Set the key missing state to true
        return; // Exit if there is no key
      }

      let fetchedAssistants = []; // Initialize fetchedAssistants to an empty array

      try {
        const openaiResponse = await fetch(
          "https://api.openai.com/v1/assistants?order=desc&limit=20",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${openai_key}`,
              "OpenAI-Beta": "assistants=v1",
            },
          }
        );

        const { data } = await openaiResponse.json();
        fetchedAssistants = data; // Update fetchedAssistants with the data from the response
      } catch (error) {
        console.error("Failed to fetch assistants:", error);
      }

      setAssistants(fetchedAssistants); // Use fetchedAssistants here

      if (fetchedAssistants) {
        const initialAssistantStatuses = fetchedAssistants.reduce(
          (status, assistant) => {
            // Use the status from the user metadata if it exists, otherwise initialize as not deployed
            status[assistant.id] = assistantsFromMetadata[assistant.id] || {
              appified_status: false,
              authentication_required: true,
              subscription_required: false,
              branding_required: true,
              logo_url: "",
              stripe_sub_required: false, // Default to false
              stripe_api_key: "", // Default to empty string
              stripe_product_ids: [], // Default to empty array
              suggestions: [], // Default to empty array
            };
            return status;
          },
          {}
        );
        setAssistantStatuses(initialAssistantStatuses); // Set the initial assistant statuses
      }
    };

    fetchAssistants();
  }, [getAccessTokenSilently, user?.sub]);

  // Function to handle updating the assistant
  const handleToggleChange = async (assistantId, updatedFields) => {
    setAssistantStatuses((prevStatus) => {
      const updatedStatus = {
        ...prevStatus,
        [assistantId]: {
          ...prevStatus[assistantId],
          ...updatedFields,
        },
      };

      // Update the user metadata with the updated status
      updateUserMetadata(user, { assistants: updatedStatus });
      return updatedStatus;
    });
  };

  const saveAndClose = async () => {
    // Save the updated statuses
    await handleToggleChange(selectedAssistant.id, {
      authentication_required: selectedAuth.value,
      subscription_required: selectedSub.value,
      branding_required: selectedBranding.value,
      logo_url: logoUrl,
      stripe_api_key: stripeApiKey,
      stripe_product_ids: stripeProductIds,
      suggestions: suggestions,
    });
    setLogoUrl(""); // Reset the logo url
    setStripeApiKey(""); // Reset the Stripe API Key
    setStripeProductIds([]); // Reset the Stripe Product IDs
    setSuggestions([]); // Reset the suggestions
    // Close the modal after saving
    closeModal();
  };

  return (
    <div className="w-full max-w-4xl mx-auto justify-center items-center mt-3">
      {isKeyMissing && (
        <>
          <h1 className="text-2xl text-center font-bold text-gray-900">
            Go to 'My Account' to connect your OpenAI Account to Appified.
          </h1>
          <h2 className="text-base text-center font-semibold my-4 text-gray-700">
            Don't have an OpenAI Account yet?{" "}
            <a
              href="https://platform.openai.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700 underline"
            >
              Sign up for free
            </a>
          </h2>
        </>
      )}

      {!isKeyMissing && !assistants && (
        <div className="text-center font-semibold text-red-500">
          Unable to connect to your OpenAI Account. Please fix your OpenAI API
          key in your account settings.
        </div>
      )}

      {!isKeyMissing && assistants && assistants?.length === 0 && (
        <>
          <h1 className="text-2xl text-center font-bold text-gray-900">
            You've connected your OpenAI Account! Now, create your first
            assistant in OpenAI.
          </h1>
          <div className="text-center text-gray-700">
            <p className="text-lg font-semibold mb-2">
              Make your first Assistant in OpenAI here:
            </p>
            <a
              href="https://platform.openai.com/assistants"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700 underline"
            >
              https://platform.openai.com/assistants
            </a>
            <p className="text-lg font-semibold mt-4 mb-2">
              If you're not sure what an OpenAI Assistant is,
            </p>
            <a
              href="https://platform.openai.com/docs/assistants/how-it-works/agents"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700 underline"
            >
              here's a good overview
            </a>
          </div>
        </>
      )}

      {!isKeyMissing && assistants && assistants?.length > 0 && (
        <>
          <h1 className="text-2xl text-center font-bold text-gray-900">
            My OpenAI Assistants
          </h1>
          {currentProduct ? (
            <h2 className="text-md text-center font-semibold mb-4 text-gray-700">
              You are subscribed to {currentProduct.name}. You have appified{" "}
              {appifiedCount} out of {currentProduct.app_limit} assistants.
            </h2>
          ) : (
            <h2 className="text-base text-center font-semibold mb-4 text-gray-700">
              Appify your first assistant for free. Subscribe to Appified.ai to
              customize it, and add more.
            </h2>
          )}

          {assistants.map((assistant, index) => (
            <div
              key={index}
              className="flex justify-between items-center bg-gray-100 shadow rounded p-4 mb-3 mx-2"
            >
              <div className="flex-grow min-w-0">
                <div className="flex items-center">
                  {assistantStatuses[assistant.id]?.logo_url && (
                    <img
                      src={assistantStatuses[assistant.id].logo_url}
                      alt={`${assistant.name || "Unnamed Assistant"} Logo`}
                      className="h-6 w-6 mr-2"
                    />
                  )}

                  <h3 className="text-xl font-semibold text-gray-900 text-left">
                    {assistant.name || "Unnamed Assistant"}
                  </h3>
                </div>
                {assistantStatuses[assistant.id]?.appified_status && (
                                    <div className="flex flex-wrap gap-2">
                    <button
                      type="button"
                      className="rounded-md bg-indigo-600 px-3 py-2 text-xs text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <Link to={`/chat/${assistant.id}`}>
                        <div className="flex items-center font-semibold">
                          <ChatBubbleBottomCenterTextIcon className="h-5 w-5 mr-1" />
                          Chat Now
                        </div>
                      </Link>
                    </button>
                    <button
                      type="button"
                      onClick={() => openModal(assistant)}
                      className="rounded-md bg-indigo-600 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <div className="flex items-center font-bold">
                        <CodeBracketIcon className="h-5 w-5 mr-1" />
                        Configure
                      </div>
                    </button>
                    <button
                      type="button"
                      onClick={() => openAnalyticsModal(assistant)}
                      className="rounded-md bg-indigo-600 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <div className="flex items-center font-bold">
                        <ChartPieIcon className="h-5 w-5 mr-1" />
                        Analytics
                      </div>
                    </button>
                  </div>
                )}
              </div>
              <div className="flex-shrink-0">
                <Switch.Group as="div" className="flex items-center">
                  <Switch.Label
                    as="span"
                    className="flex items-center font-semibold mr-4"
                  >
                    {assistantStatuses[assistant.id]?.appified_status && (
                      <SparklesIcon className="h-5 w-5 mr-1" />
                    )}
                    {assistantStatuses[assistant.id]?.appified_status
                      ? "Appified"
                      : "Appify?"}
                  </Switch.Label>
                  <Switch
                    checked={assistantStatuses[assistant.id]?.appified_status}
                    onChange={() =>
                      handleToggleChange(assistant.id, {
                        appified_status:
                          !assistantStatuses[assistant.id]?.appified_status,
                      })
                    }
                    className={`${
                      assistantStatuses[assistant.id]?.appified_status
                        ? "bg-green-500"
                        : "bg-gray-400"
                    }
                    relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Deploy Assistant</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        assistantStatuses[assistant.id]?.appified_status
                          ? "translate-x-6"
                          : "translate-x-0"
                      }
                      pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>
          ))}
        </>
      )}

      {assistants?.length === 0 && (
        <div className="w-full px-4 pt-16">
          <h2 className="text-2xl mb-6 text-center font-bold tracking-tight text-gray-900 sm:text-3xl">
            Getting Started FAQs
          </h2>
          <div className="mx-auto w-full max-w-4xl rounded-2xl bg-white p-2">
            {faqs.map((faq, index) => (
              <Disclosure
                as="div"
                className={`${index > 0 ? "mt-2" : ""}`}
                key={faq.question}
              >
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-base font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                      <span>{faq.question}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-base text-gray-500">
                      {faq.answer}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </div>
        </div>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[100]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-center text-lg font-medium leading-6 text-gray-900 mb-2"
                  >
                    Configure {selectedAssistant?.name || "Unnamed Assistant"}{" "}
                    {!subscriptionStatus.subscription ||
                    subscriptionStatus.subscription.status !== "active" ? (
                      <div className="text-sm text-gray-500 bg-yellow-100 border-l-4 border-yellow-500 p-2">
                        <strong>Note:</strong> Modifying the settings for your
                        assistant requires a paid subscription.
                      </div>
                    ) : null}
                  </Dialog.Title>
                  <div className="mb-5">
                    <label
                      htmlFor="logoUrl"
                      className="block text-sm font-medium text-gray-900 mb-2"
                    >
                      Logo URL:
                      <p className="text-xs text-gray-500">
                        A logo for your assistant.
                      </p>
                    </label>
                    <input
                      type="text"
                      name="logoUrl"
                      id="logoUrl"
                      value={logoUrl}
                      onChange={(e) => setLogoUrl(e.target.value)}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder="https://example.com/logo.png"
                    />
                  </div>
                  <div className="mb-5">
                    <label
                      htmlFor="suggestionInput"
                      className="block text-sm font-medium text-gray-900 mb-2"
                    >
                      Suggested Messages:
                      <p className="text-xs text-gray-500">
                        Suggested messages will be shown to your users when
                        starting a chat with this assistant.
                      </p>
                    </label>
                    <input
                      type="text"
                      name="suggestionInput"
                      id="suggestionInput"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder="Type and press Enter to add a suggested message"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && e.target.value.trim() !== "") {
                          setSuggestions([...suggestions, e.target.value]);
                          e.target.value = ""; // Clear input after adding
                        }
                      }}
                    />
                  </div>
                  <ul className="mb-5 space-y-2">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center bg-gray-200 p-2 rounded-lg shadow"
                      >
                        <span className="text-sm font-medium">
                          {suggestion}
                        </span>
                        <button
                          onClick={() => removeSuggestion(index)}
                          className="ml-4 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                        >
                          X
                        </button>
                      </li>
                    ))}
                  </ul>

                  <div className="mb-5">
                    <Listbox value={selectedAuth} onChange={setSelectedAuth}>
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                            Require users to login to Appified to chat:
                          </Listbox.Label>
                          <div className="relative mt-2">
                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                              <span className="block truncate">
                                {selectedAuth.name}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {authOptions.map((option) => (
                                  <Listbox.Option
                                    key={option.value}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={option}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {option.name}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  <div className="mb-5">
                    <Listbox
                      value={selectedBranding}
                      onChange={setSelectedBranding}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                            Show Appified Branding:
                          </Listbox.Label>
                          <div className="relative mt-2">
                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                              <span className="block truncate">
                                {selectedBranding.name}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                {brandingOptions.map((option, optionIdx) => (
                                  <Listbox.Option
                                    key={optionIdx}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={option}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {option.name}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  <div className="mb-5">
                    <Listbox
                      value={selectedSub}
                      onChange={setSelectedSub}
                      disabled={!selectedAuth.value} // Disable the subscription option if authentication is not required
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                            Require a Stripe Subscription:
                          </Listbox.Label>
                          <div className="relative mt-2">
                            <Listbox.Button
                              className={`relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6 ${
                                !selectedAuth.value ? "cursor-not-allowed" : ""
                              }`}
                              disabled={!selectedAuth.value} // Disable the button if login is not required
                            >
                              <span className="block truncate">
                                {selectedAuth.value
                                  ? selectedSub.name
                                  : "Disabled - Login Required"}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            {!selectedAuth.value && (
                              <p className="mt-2 text-sm text-gray-500">
                                Must require users to login to enable
                                subscription requirement.
                              </p>
                            )}

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                {subOptions.map((option, optionIdx) => (
                                  <Listbox.Option
                                    key={optionIdx}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={option}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {option.name}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  {selectedSub.value && (
                    <>
                      <div className="mb-4">
                        <label
                          htmlFor="stripeApiKey"
                          className="block mb-2 text-sm font-medium leading-6 text-gray-900"
                        >
                          Stripe API Key:
                        </label>
                        <input
                          type="text"
                          id="stripeApiKey"
                          value={stripeApiKey}
                          onChange={(e) => setStripeApiKey(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          placeholder="Enter Stripe API Key"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="stripeProductIds"
                          className="block mb-2 text-sm font-medium leading-6 text-gray-900"
                        >
                          Stripe Product IDs (comma-separated):
                        </label>
                        <input
                          type="text"
                          id="stripeProductIds"
                          value={stripeProductIds.join(",")}
                          onChange={(e) =>
                            setStripeProductIds(e.target.value.split(","))
                          }
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          placeholder="prod_XXXX,prod_YYYY"
                        />
                      </div>
                    </>
                  )}

                  <div className="mt-2">
                    <p className="text-sm font-medium text-gray-900">
                      To embed this assistant on your website, use this URL:
                    </p>
                    <div className="bg-gray-100 py-4 px-2 rounded text-xs">
                      <Link
                        to={`/embed/${selectedAssistant?.id}`}
                        className="text-blue-500 hover:text-blue-700 underline"
                      >
                        {`${window.location.origin}/embed/${selectedAssistant?.id}`}
                      </Link>
                    </div>
                    <p className="text-sm text-gray-900 mt-2">
                      <a
                        href="https://www.iframe-generator.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-700 underline"
                      >
                        iframe generator
                      </a>{" "}
                      can help you embed this assistant in your HTML
                    </p>
                  </div>

                  <div className="mt-4 flex justify-center">
                    <button
                      type="button"
                      disabled={
                        !subscriptionStatus.subscription ||
                        subscriptionStatus.subscription.status !== "active"
                      } // Check if subscription exists and is active
                      className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ${
                        subscriptionStatus.subscription &&
                        subscriptionStatus.subscription.status === "active"
                          ? "bg-blue-100 text-blue-900 hover:bg-blue-200 focus-visible:ring-blue-500"
                          : "bg-gray-100 text-gray-500 cursor-not-allowed"
                      }`}
                      onClick={saveAndClose}
                    >
                      Save and Close
                      {(!subscriptionStatus.subscription ||
                        subscriptionStatus.subscription.status !==
                          "active") && <span> (Requires Subscription)</span>}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    {/* Analytics Modal */}
    <Transition appear show={isAnalyticsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={closeAnalyticsModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-center text-lg font-medium leading-6 text-gray-900 mb-2"
                >
                  Analytics for {selectedAssistant?.name || "Unnamed Assistant"}
                </Dialog.Title>
                <div className="mt-2">
                <Callout title="Scope of Analytics" icon={RiErrorWarningFill}>
                  <p className="text-sm text-gray-500">
                    These analytics cover the assistant's activity for the past 30 days on Appified. Usage directly on the OpenAI platform is not included.
                  </p>
                </Callout>

                  <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <Card className="mx-auto max-w-xs" decoration="top" decorationColor="indigo">
                      <Text>Total Threads</Text>
                      {isLoadingAnalytics ?       <IconSpinner className="h-6 w-6 animate-spin" /> : <Metric>{analyticsData.metrics.threads}</Metric>}
                    </Card>
                    <Card className="mx-auto max-w-xs" decoration="top" decorationColor="indigo">
                      <Text>Threads by Anonymous Users</Text>
                      {isLoadingAnalytics ? <IconSpinner className="h-6 w-6 animate-spin" /> : <Metric>{analyticsData.metrics.anonymousUserThreads}</Metric>}
                    </Card>
                    <Card className="mx-auto max-w-xs" decoration="top" decorationColor="indigo">
                      <Text>Threads by Logged In Users</Text>
                      {isLoadingAnalytics ? <IconSpinner className="h-6 w-6 animate-spin" /> : <Metric>{analyticsData.metrics.authenticatedUserThreads}</Metric>}
                    </Card>
                  </div>
                  <div className="mt-4">

                    {isLoadingAnalytics ? (
                      <IconSpinner className="h-6 w-6 animate-spin" />
                    ) : analyticsData.threads.length > 0 ? (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableHeaderCell>User Email</TableHeaderCell>
                            <TableHeaderCell>Created At</TableHeaderCell>
                            <TableHeaderCell>Link to Thread</TableHeaderCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {analyticsData.threads.map((thread) => (
                            <TableRow key={thread.thread_id}>
                              <TableCell>
                                {thread.user_email === 'anonymous_user@example.com' ? (
                                  <Badge color="blue" tooltip="Anonymous User">Anonymous User</Badge>
                                ) : (
                                  thread.user_email
                                )}
                              </TableCell>
                              <TableCell>{new Date(thread.create_timestamp).toLocaleString()}</TableCell>
                              <TableCell>
                                <a
                                  href={`https://platform.openai.com/playground/assistants?assistant=${thread.assistant_id}&mode=assistant&thread=${thread.thread_id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-500 hover:text-blue-700 underline"
                                >
                                  View Full Thread
                                </a>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p className="text-sm text-gray-500">No analytics data available.</p>
                    )}
                  </div>
                </div>
                <div className="mt-4 flex justify-center">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium bg-blue-100 text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeAnalyticsModal}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
    </div>
  );
};

export default MyAssistants;
