import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CodeBlock, CopyBlock, github } from "react-code-blocks";
import { Disclosure } from "@headlessui/react";

import { BellAlertIcon, ComputerDesktopIcon, GlobeAltIcon } from "@heroicons/react/24/outline";

const api_call = `
{
    "name": "api_fetch_joke",
    "description": "Fetches a joke from a specified API endpoint using the given request type and search term",
    "parameters": {
      "type": "object",
      "properties": {
        "endpoint_url": {
          "type": "string",
          "description": "The API endpoint URL"
        },
        "request_type": {
          "type": "string",
          "enum": [
            "GET",
            "POST",
            "PUT",
            "DELETE"
          ],
          "description": "The type of HTTP request to make (GET, POST, etc.)"
        },
        "term": {
          "type": "string",
          "description": "The search term to find a related joke"
        }
      },
      "custom_headers": {
        "type": "object",
        "description": "Optional custom headers to be added to the API request"
      },
      "required": [
        "endpoint_url",
        "request_type",
        "term"
      ]
    }
  }`;

const read_url = `
  {
    "name": "scrape_url",
    "description": "Scrape the readable text from a URL",
    "parameters": {
      "type": "object",
      "properties": {
        "url": {
          "type": "string",
          "description": "Full URL to scrape starting with http"
        }
      },
      "required": [
        "url"
      ]
    }
  }`;

const webhook_example = `
{
  "name": "webhook_slack",
  "description": "Send a notification message to a Slack channel via a webhook URL",
  "parameters": {
    "type": "object",
    "properties": {
      "webhook_url": {
        "type": "string",
        "description": "The Slack webhook URL to which the message will be sent"
      },
      "text": {
        "type": "string",
        "description": "The message content to be sent to the Slack channel"
      }
    },
    "required": [
      "webhook_url",
      "text"
    ]
  }
}`;

const zapier_webhook = `
{
  "name": "webhook_zapier",
  "parameters": {
    "type": "object",
    "properties": {
      "webhook_url": {
        "type": "string",
        "description": "The webhook URL to trigger the Zap"
      }
    },
    "required": [
      "webhook_url"
    ]
  },
  "description": "Trigger a Zapier webhook"
}
`;

const Docs = () => {
  const { user, isAuthenticated } = useAuth0();

  return (
    isAuthenticated && (
      <div className="bg-white">
        <div className="relative isolate px-8 lg:px-10">
          <div className="mx-auto max-w-7xl py-6">
            <div className="text-center">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">Appified Docs</h1>
              <h2 className="text-3xl mt-5 font-bold text-gray-700">Advanced Features for your OpenAI Assistants, powered by Appified</h2>
            </div>
            <div className="mt-12">
              <h3 className="text-2xl font-bold text-gray-900">Table of Contents</h3>
              <ul className="list-disc ml-6">
                <li>
                  <a href="#introduction" className="text-indigo-600">
                    Introduction
                  </a>
                </li>
                <li>
                  <a href="#authentication" className="text-indigo-600">
                    Authentication
                  </a>
                </li>
                <li>
                  <a href="#functions" className="text-indigo-600">
                    Assistant Functions
                  </a>
                  <ul className="list-disc ml-10">
                    <li>
                      <a href="#api_calls" className="text-indigo-600">
                        API Calls
                      </a>
                    </li>
                    <li>
                      <a href="#webhooks" className="text-indigo-600">
                        Webhooks
                      </a>
                    </li>
                    <li>
                      <a href="#url_reading" className="text-indigo-600">
                        URL Reading
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="mt-12" id="introduction">
              <h3 className="text-2xl font-bold text-gray-900">Introduction</h3>
              <p className="mt-3 text-gray-600 leading-relaxed">
                Welcome to Appified.ai. We integrate with your OpenAI Account and provide a UI + API for your OpenAI Assistants so you can embed a chat experience anywhere. This page describes advanced features you can give your Assistants using
                Appified.
              </p>
            </div>
            <div className="mt-12" id="authentication">
              <h3 className="text-2xl font-bold text-gray-900">Authentication</h3>
              <p className="mt-3 text-gray-600 leading-relaxed">
                The Appified URL for your assistant (which includes the OpenAI Assistant ID) allows anyone to chat with your assistant. The configuration setting "Require users to login to Appified to chat" allows you to require users to log in to
                Appified before chatting with your assistant.
              </p>
              <p className="mt-3 text-gray-600 leading-relaxed">
                To authenticate tool calls, you can use the authToken parameter. This allows you to pass an authorization token in any API calls your assistant makes. The token is passed to your assistant as a query parameter in the URL, for example:
                https://appified.ai/chat/assistant-id?authToken=your-token-here. Your assistant never sees the authToken, and it is not stored in the assistant's memory. Appified will parse the authToken, and pass it along with your assistant's API
                calls as a Bearer token in the Authorization header.
              </p>
            </div>
            <div className="mt-12" id="functions">
              <h3 className="text-2xl font-bold text-gray-900 my-3">Assistant Functions</h3>
              <p className="mt-3 text-gray-600 leading-relaxed">OpenAI Assistants are most useful when they can take actions based on user input and reasoning. This happens via what OpenAI refers to as function calling.</p>
              <p className="mt-3 text-gray-600 leading-relaxed">
                Appified simplifies the process of enabling your assistant to call functions by handling the function calls for you. To activate function calling, you can add functions that follow the schemas provided below to your assistant on{" "}
                <a href="https://platform.openai.com/assistants" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-800">
                  OpenAI
                </a>
                . These functions are written in Python and executed on our servers.
              </p>
              <h4 className="text-xl font-bold text-gray-800 mt-6 flex items-center" id="api_calls">
                <ComputerDesktopIcon className="w-6 h-6 mr-2" /> Let your Assistant Make API Calls
              </h4>
              <p className="mt-3 text-gray-600 leading-relaxed">
                Any function that you add to your assistant that has a name starting with 'api_' will be treated as an API call. Appified will handle the API call and return the API response to the assistant.
              </p>
              <p className="mt-3 text-gray-600 leading-relaxed">
                API calls have 2 required parameters, endpoint_url and request_type. All other parameters will be passed to the API call as query parameters or in the request body (based on the request type). You can also include a custom_headers
                object to add custom headers to the API request.
              </p>
              <p className="mt-3 text-gray-600 leading-relaxed">For file uploads, you can use the files parameter with POST and PUT requests. The authToken parameter can be used to add a Bearer token to the Authorization header.</p>

              <p className="mt-3 text-gray-600 leading-relaxed">
                Here's an example function that fetches a joke from an API endpoint using the given request type and search term. This function works in Appified because it has a name starting with 'api_' and has the required parameters endpoint_url
                and request_type.
              </p>
              <p className="mt-3 text-gray-600 leading-relaxed">In this example, the assistant will also pass the parameter term with a value, and Appified will pass that to the API call as a query parameter with the same name.</p>
              <CopyBlock text={api_call} language="json" showLineNumbers="True" theme={github} />
              <h4 className="text-xl font-bold text-gray-800 mt-6 flex items-center" id="webhooks">
                <BellAlertIcon className="w-6 h-6 mr-2" /> Let your Assistant call Webhooks
              </h4>
              <p className="mt-3 text-gray-600 leading-relaxed">
                Any function that you add to your assistant that has a name starting with 'webhook_' will be treated as a webhook. Appified will handle the webhook call by making a POST request to the webhook URL with all of the parameters you pass
                to the function.
              </p>
              <p className="mt-3 text-gray-600 leading-relaxed">Webhooks have 1 required parameter: webhook_url. All other parameters will be passed to the webhook in the request body.</p>
              <h5 className="text-lg font-bold text-gray-800 mt-6">Example #1: Slack Webhook</h5>
              <p className="mt-3 text-gray-600 leading-relaxed">
                Here's an example function that sends a notification to a Slack channel via a webhook URL. This function works in Appified because it has a name starting with 'webhook_' and has the required parameter webhook_url.
              </p>
              <p className="mt-3 text-gray-600 leading-relaxed">In this example, the assistant will also pass the parameter text with a value, and Appified will pass that to the webhook as a query parameter with the same name.</p>
              <CopyBlock text={webhook_example} language="json" showLineNumbers="True" theme={github} />
              <h5 className="text-lg font-bold text-gray-800 mt-6">Example #2: Zapier Webhook</h5>
              <p className="mt-3 text-gray-600 leading-relaxed">Here's an example function that triggers a Zapier webhook. This function works in Appified because it has a name starting with 'webhook_' and has the required parameter webhook_url.</p>
              <CopyBlock text={zapier_webhook} language="json" showLineNumbers="True" theme={github} />
              <h4 className="text-xl font-bold text-gray-800 mt-6 flex items-center" id="url_reading">
                <GlobeAltIcon className="w-6 h-6 mr-2" /> Let your Assistant Read URLs
              </h4>
              <p className="mt-3 text-gray-600 leading-relaxed">This function will read the text from a URL and return it to the assistant.</p>
              <p className="mt-3 text-gray-600 leading-relaxed">The scrape_url function has 1 required parameter: url. Appified will scrape the URL and return the readable text to the assistant.</p>
              <CopyBlock text={read_url} language="json" showLineNumbers="True" theme={github} />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Docs;
