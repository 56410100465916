// auth0Helper.js
import { useAuth0 } from "@auth0/auth0-react";

const domain = "appified.us.auth0.com";

export const useAuth0Helper = () => {
  const { getAccessTokenSilently } = useAuth0();

  const updateUserMetadata = async (user, newMetadata) => {
    const token = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "update:current_user_metadata",
    });

    const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

    const metadataResponse = await fetch(userDetailsByIdUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_metadata: newMetadata,
      }),
    });

    if (!metadataResponse.ok) {
      // Handle error
      console.error('Failed to update user metadata');
    }
  };

  return { updateUserMetadata };
};