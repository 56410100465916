import React from 'react';

const Banner = ({ subscriptionStatus, isSubscriptionFetching }) => {

  if (isSubscriptionFetching) {
    return null;
  }

  const isSubscribed = subscriptionStatus && subscriptionStatus.subscription?.items.data.length > 0;

  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-fuchsia-50 bg-opacity-100 px-6 py-2.5 sm:px-3.5">
      <div className="flex flex-wrap items-center mx-auto gap-x-4 gap-y-2">
        {isSubscribed ? (
          <p className="text-sm text-center leading-6 text-gray-900">
            <strong className="font-semibold">Thanks for subscribing to Appified!</strong>
            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
              <circle cx={1} cy={1} r={1} />
            </svg>
            Need help or have questions? Use the chat or email us at <a href="mailto:patrick@appified.ai" className="underline">patrick@appified.ai</a>
          </p>
        ) : (
          <p className="text-sm text-center leading-6 text-gray-900">
            <strong className="font-semibold">Subscribe to Appified on the 'My Account' page to configure your assistants</strong>
            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
              <circle cx={1} cy={1} r={1} />
            </svg>
            Need help or have questions? Use the chat or email us at <a href="mailto:patrick@appified.ai" className="underline">patrick@appified.ai</a>
          </p>
        )}
      </div>
    </div>
  );
};

export default Banner;