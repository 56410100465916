"use client";

import { useEffect, useRef, useState } from "react";
import Textarea from "react-textarea-autosize";
import { ArrowUpCircleIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { IconSpinner } from "./ui/Icons.js";
import { Button } from "./ui/Button.js";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "./ui/Tooltip.js";
import { useEnterSubmit } from "./hooks/use-enter-submit";

export function ChatInputForm({
  selectedAssistant,
  status,
  input,
  submitMessage,
  handleInputChange,
}) {
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [attachments, setAttachments] = useState([]);
  const [isUploading, setIsUploading] = useState(false); // state variable to track upload status
  const { formRef, onKeyDown } = useEnterSubmit();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleFileUpload = async (files) => {
    setIsUploading(true);
    const uploadPromises = files.map(async (file) => {
      try {
        const response = await fetch("/api/getUploadUrl", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            filename: file.name,
            contentType: file.type,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to get pre-signed URL.");
        }

        const { url, key } = await response.json();

        const formData = new FormData();
        formData.append("file", file);

        const uploadResponse = await fetch(url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });

        if (!uploadResponse.ok) {
          throw new Error("S3 Upload Error");
        }

        // determine the type of file uploaded (image or non-image)
        const fileType = file.type.startsWith("image/") ? "image" : "non-image";

        const assistantResponse = await fetch("/api/addFileToAssistant", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fileUuid: key,
            assistantId: selectedAssistant.id,
            fileType: fileType,
          }),
        });

        if (!assistantResponse.ok) {
          throw new Error("Failed to add file to assistant.");
        }

        const { fileId } = await assistantResponse.json();
        console.log("File added to assistant:", fileId);

        return { type: fileType, fileId: fileId };
      } catch (error) {
        console.error("File upload and assistant addition error:", error);
        return null;
      }
    });

    const uploadedAttachments = await Promise.all(uploadPromises);
    const successfulUploads = uploadedAttachments.filter((key) => key != null);
    setAttachments(successfulUploads);
    setIsUploading(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length) {
      handleFileUpload(files);
    }
  };

  const handleSubmit = () => {
    if (input.trim()) {
      submitMessage(input, attachments); // Pass input and attachments directly
      setAttachments([]); // Reset attachments after submission
    }
  };

  return (
    <TooltipProvider>
      <form
        ref={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          if (window.innerWidth < 600) {
            event.target.blur();
          }
          handleSubmit(); // Call handleSubmit directly
        }}
        className="relative flex max-h-60 w-full grow flex-col overflow-hidden bg-background px-8 sm:rounded-md sm:border sm:px-12"
      >
        <Textarea
          ref={inputRef}
          className="min-h-[60px] w-full resize-none bg-transparent pl-4 pr-10 py-[1.3rem] focus-within:outline-none sm:text-sm border-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent"
          placeholder={`Ask ${
            selectedAssistant ? selectedAssistant.name : "AI"
          } a question...`}
          autoFocus
          spellCheck={true}
          autoComplete="on"
          autoCorrect="on"
          value={input}
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
        />
        <div className="absolute right-0 top-[13px] sm:right-2">
          <Tooltip>
            <input
              type="file"
              multiple // Allow multiple file selection if needed
              ref={fileInputRef}
              onChange={handleFileChange}
              className="text-sm text-stone-500
                   file:mr-5 file:py-1 file:px-3 file:border-[1px]
                   file:text-xs file:font-medium
                   file:bg-stone-50 file:text-stone-700
                   hover:file:cursor-pointer hover:file:bg-blue-50
                   hover:file:text-blue-700"
              style={{ display: "none" }}
              accept="image/*,video/*,audio/*,.pdf,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
            />
            <TooltipTrigger asChild>
              <Button
                onClick={(event) => {
                  event.preventDefault(); // Prevent form submission
                  fileInputRef.current.click();
                }}
                disabled={status !== "awaiting_message"}
                variant="outline"
                size="icon"
                className="rounded-full bg-background p-0 mr-1"
              >
                <span className="relative">
                  {isUploading ? (
                    <IconSpinner className="w-6 h-6 animate-spin" /> // Show spinner when uploading
                  ) : (
                    <>
                      <PaperClipIcon className="w-6 h-6" />
                      {/* Badge showing the number of attached files */}
                      {attachments.length > 0 && (
                        <span className="absolute -top-3 -right-3 flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 text-xs text-white">
                          {attachments.length}
                        </span>
                      )}
                    </>
                  )}
                </span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>Attach files</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="submit"
                disabled={
                  status !== "awaiting_message" || !input.trim() 
                }
                variant="outline"
                size="icon"
                className="rounded-full bg-background p-0"
              >
                {status === "in_progress" ? (
                  <IconSpinner className="w-6 h-6 animate-spin" />
                ) : (
                  <>
                    <ArrowUpCircleIcon className="w-6 h-6" />
                    <span className="sr-only">Send</span>
                  </>
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent>Send message</TooltipContent>
          </Tooltip>
        </div>
      </form>
    </TooltipProvider>
  );
}
