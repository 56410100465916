import LogoutButton from "./LogoutButton";
import { useState } from "react";
import { Dialog, Popover } from "@headlessui/react";
import { HashLink as Link } from "react-router-hash-link";
import { formatDistanceToNow } from "date-fns";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDoubleDownIcon,
  ChatBubbleBottomCenterIcon,
} from "@heroicons/react/24/outline";

import { useChatData } from "./contexts/ChatHistory";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function truncateMessage(message, maxLength) {
  return message.length > maxLength
    ? message.substring(0, maxLength) + "..."
    : message;
}

export default function Header({ isAuthenticated, loginWithRedirect }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { chatData, isFetchingChat, fetchChatData } = useChatData();
  // Function to close the mobile menu
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className="py-4  sm:py-5 sticky z-20">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="flex items-center justify-between">
          <div className="shrink-0">
            <a href="/" className="">
              <img
                className="w-auto h-12"
                src="/appified_logo.png"
                alt="appified.ai"
              />
            </a>
          </div>

          <div className="hidden lg:flex lg:items-center lg:space-x-4 lg:ml-12">
            {!isAuthenticated && (
              <>
                <Link
                  smooth
                  to="/#pricing"
                  className="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-gray-900 transition-all duration-200 border border-transparent rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ring-300"
                >
                  Pricing
                </Link>
                <Link
                  smooth
                  to="/#faq"
                  className="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-gray-900 transition-all duration-200 border border-transparent rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ring-300"
                >
                  FAQ
                </Link>
              </>
            )}
            <Link
              smooth
              to="/contactus"
              className="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-gray-900 transition-all duration-200 border border-transparent rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ring-300"
            >
              Contact Us
            </Link>
            <a
              href="/account"
              className="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-gray-900 transition-all duration-200 border border-transparent rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ring-300"
            >
              Manage my Assistants
            </a>
            {isAuthenticated &&
              chatData &&
              chatData.threads &&
              chatData.threads.length > 0 &&
              !isFetchingChat && (
                <Popover className="relative">
                  <Popover.Button className="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-gray-900 transition-all duration-200 border border-transparent rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ring-300">
                    Chat History
                    <ChevronDoubleDownIcon
                      className="ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 mt-3 w-screen max-w-sm px-4 sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-1">
                        {chatData.threads.map((chat) => {
                          const truncatedMessage = truncateMessage(
                            chat.first_message,
                            50
                          ); // Truncate after 50 characters
                          const localDate = new Date(chat.create_timestamp);

                          // Return the Link component for each chat
                          return (
                            <Link
                              key={chat.thread_id}
                              to={`/chat/${chat.assistant_id}/${chat.thread_id}`}
                              className="flex flex-col p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100"
                            >
                              <div className="flex items-center">
                                <ChatBubbleBottomCenterIcon
                                  className="h-5 w-5 mr-2 text-gray-700"
                                  aria-hidden="true"
                                />
                                {truncatedMessage}
                              </div>
                              <span className="text-xs text-gray-500 mt-1">
                                {formatDistanceToNow(localDate, {
                                  addSuffix: true,
                                })}
                              </span>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </Popover.Panel>
                </Popover>
              )}
          </div>

          {/* Login/Logout for Desktop */}
          <div className="hidden lg:flex lg:items-center lg:justify-end lg:space-x-4 lg:ml-auto">
            {isAuthenticated ? (
              <LogoutButton />
            ) : (
              <button
                className="inline-flex items-center justify-center px-6 py-2.5 text-base font-medium text-gray-900 transition-all duration-200 border border-gray-900 rounded-full hover:bg-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ring-900"
                onClick={loginWithRedirect}
              >
                Login
              </button>
            )}
          </div>

          <div className="flex ml-4 lg:hidden">
            {!mobileMenuOpen && ( // Only show the button when the menu is closed
              <button
                type="button"
                className="inline-flex items-center p-2.5 text-gray-900 duration-200 border border-gray-900 rounded-full hover:bg-gray-900 hover:text-white transiton-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 focus:bg-gray-900 focus:text-white"
                onClick={() => setMobileMenuOpen(true)}
                aria-expanded={mobileMenuOpen}
              >
                <Bars3Icon className="w-6 h-6" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
      </div>

      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-30" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-30 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">appified.ai</span>
              <img className="h-8 w-auto" src="/appified_logo.png" alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  smooth
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Home
                </Link>
                <Link
                  smooth
                  to="/#pricing"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Pricing
                </Link>
                <Link
                  smooth
                  to="/contactus"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Contact Us
                </Link>
                <a
                  href="/account"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Manage my Assistants
                </a>
                {isAuthenticated &&
                  chatData &&
                  chatData.threads &&
                  chatData.threads.length > 0 &&
                  !isFetchingChat && (
                    <Popover>
                      <Popover.Button className="flex items-center text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 -mx-3 block rounded-lg px-3 py-2">
                        Chat History
                        <ChevronDoubleDownIcon
                          className="ml-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      </Popover.Button>
                      <Popover.Panel className="absolute z-10 mt-3 w-screen max-w-sm px-4 sm:px-0">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-1">
                            {chatData.threads.map((chat) => {
                              const truncatedMessage = truncateMessage(
                                chat.first_message,
                                50
                              ); // Truncate after 50 characters
                              // Convert UTC timestamp to local time
                              const localDate = new Date(chat.create_timestamp);

                              return (
                                <Link
                                  key={chat.thread_id}
                                  to={`/chat/${chat.assistant_id}/${chat.thread_id}`}
                                  className="flex flex-col p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100"
                                  onClick={closeMobileMenu}
                                >
                                  <div className="flex items-center">
                                    <ChatBubbleBottomCenterIcon
                                      className="h-5 w-5 mr-2 text-gray-700"
                                      aria-hidden="true"
                                    />
                                    {truncatedMessage}
                                  </div>
                                  <span className="text-xs text-gray-500 mt-1">
                                    {formatDistanceToNow(localDate, {
                                      addSuffix: true,
                                    })}
                                  </span>
                                </Link>
                              );
                            })}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Popover>
                  )}
              </div>
              <div className="py-6">
                {isAuthenticated ? (
                  <LogoutButton />
                ) : (
                  <button
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={loginWithRedirect}
                  >
                    Login
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
