import { useState } from 'react';
import { useAuth0Helper } from './auth0Helper';

const domain = "appified.us.auth0.com";


const SubscribeManageAccount = ({ user }) => {
  const { updateUserMetadata } = useAuth0Helper();
  const [apiKey, setApiKey] = useState('');

  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await updateUserMetadata(user, { openai_key: apiKey });

    alert("API key saved successfully! Click on My Apps to get started.");
  };

  return (
      <div className="w-5/6 lg:w-2/3 items-center mx-auto">
        <h1 className="text-2xl py-2 font-bold text-center">Manage Account</h1>
        <form onSubmit={handleSubmit} className="w-full max-w-xl mx-auto mt-5">
          <div className="flex justify-between items-center mb-6">
            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-password">
              OpenAI API Key
            </label>
            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
              id="inline-password" 
              type="password" 
              value={apiKey} 
              onChange={handleApiKeyChange} 
              placeholder="sk-******************"/>
          </div>
          <div className="flex justify-end">
            <button className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit">
              Save
            </button>
          </div>
        </form>
        <div className='leading-5'>
          <h2 className="text-2xl mt-8 py-4 font-bold text-center">Pick your Appified Plan</h2>
          <h3 className="text-md text-center">Subscribe via Stripe for instant access</h3>
          <stripe-pricing-table pricing-table-id="prctbl_1OZM86KFxdT22QzoW6j8MEz0"
          publishable-key="pk_live_51NJmx5KFxdT22QzoUTwBpAcJDVz5ATfLHqpkXyEsvm6VhO0ihXtfSIpYXaSyPsimxe8EaESS48folMuJ3l313grm00wxZe9NZw">
          </stripe-pricing-table>

            <p class="mb-4 text-lg leading-8 text-center text-gray-600">💼 <a href="https://billing.stripe.com/p/login/7sI8zw5NL86i0aA5kk" className="underline" target="_blank" rel="noopener noreferrer">Manage my Subscription on Stripe</a></p>
        </div>
      </div>
  );
};

export default SubscribeManageAccount;